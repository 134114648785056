<template>
  <div class='element materialShadow popUpScrollList customerPage item' :id="containerId">
    <map-component v-if="position" :points="position" class="map"/>
    <div class="card deleted" v-if="deleteWasPerformed">
      <h2 class="headline">{{ x('deleted') }}</h2>
    </div>

    <!--  ADDRESS CARD -->

    <div class="card" :class="{ editing: isEditingAddress, displaying: !isEditingAddress }">
      <template v-if="isEditingAddress">
        <form class="editing-form" autocomplete="off">
          <div class="item">
            <label for="customerName">{{ x('customers.Name')}}</label><br/>
            <input id="customerName" type="text" autocomplete="nope"
                   class="lsc-input lsc-rounded" v-model="editedData.name">
          </div>
          <div class="item">
            <label for="customerEmail">{{ x('customers.Email')}}</label><br/>
            <input id="customerEmail" type="text" autocomplete="nope"
                   class="lsc-input lsc-rounded" v-model="editedData.email">
          </div>
          <div class="item">
            <label for="customerAddress">{{x('customers.Address')}}</label><br/>
            <input id="customerAddress" type="text" autocomplete="nope" class="lsc-input lsc-rounded"
                   v-model="editedData.address" @change="isLocationDirty=true">
          </div>
          <div class="item">
            <label for="customerPostalCode">{{x('customers.PostalCode')}}</label><br/>
            <input id="customerPostalCode" type="text" autocomplete="nope" class="lsc-input lsc-rounded"
                   v-model="editedData.postalCode" @change="isLocationDirty=true">
          </div>
          <div class="item">
            <label for="customerCity">{{x('customers.City')}}</label><br/>
            <input id="customerCity" type="text" autocomplete="nope" class="lsc-input lsc-rounded"
                   v-model="editedData.city" @change="isLocationDirty=true">
          </div>
          <div class="item">
            <button type="button" class="lsc-button lsc-rounded fullWidth map-button"
                    @click="lookupAddress">
              <icon name="fa-compass" />
            </button>
          </div>
          <div class="item">
            <label for="customerLatitude">{{x('customers.Latitude')}}</label><br/>
            <input id="customerLatitude" type="text" autocomplete="nope" class="lsc-input lsc-rounded"
                   v-model="editedData.latitude">
          </div>
          <div class="item">
            <label for="customerLongitude">{{x('customers.Longitude')}}</label><br/>
            <input id="customerLongitude" type="text" autocomplete="nope" class="lsc-input lsc-rounded"
                   v-model="editedData.longitude">
          </div>
          <div class="item">
            <button type="button" class="lsc-button lsc-rounded fullWidth save"
                    :disabled="isUploading" @click="saveCustomer">
              {{x('save')}}
            </button>
          </div>
          <div class="item">
            <button type="button" class="lsc-button lsc-rounded fullWidth cancel"
                    :disabled="isUploading" @click="cancel">
              {{x('cancel')}}
            </button>
          </div>
        </form>
      </template>
      <template v-else>
        <h2>{{ loadedCustomer.name }}</h2>
        <p class="small">{{ loadedCustomer.address }}</p>
        <p class="small">{{ loadedCustomer.postalCode }} {{ loadedCustomer.city }}</p>
        <p class="small customer-extra" v-if="loadedCustomer.extra1 !== ''">{{ loadedCustomer.extra1 }}</p>
        <button type="button" class="lsc-button lsc-rounded fullWidth map-button"
                @click="openMap">{{x('viewOnLargeMap')}}</button>
        <button v-if="user.userRole === 'internal'"
                type="button" class="lsc-button lsc-rounded fullWidth map-button edit"
                @click="editAddress">{{ x('editAddress')}}</button>
      </template>
    </div>

    <!-- CUSTOMER PICTURES -->

    <div class="customer-images">
      <h2 class="headline">{{ x('customerPictures') }}:</h2>
      <p class="info-text">{{ x('customerPicturesInfo')}}</p>
      <p class="no-images" v-if="images.length === 0">{{ x('noCustomerPictures')}}</p>
      <div class="topImage" v-if="images.length > 0">
        <span class="swipe_container">
          <div class="swipe_item" v-for="(picture, index) in images" :key="index">
            <img :src="config.imageBaseUrl + picture.name" @click="zoomImage(index)">
            <p v-if="!isEmpty(picture.comment)" class="image-comment">{{picture.comment}}</p>
          </div>
        </span>
      </div>
      <div v-if="images.length > 1" class="topPreviews">
        <div v-for="(picture, index) in images" :key="index" class="imageWrapper">
          <a @click='swipeToImage(index)'
             :style="{ background: 'url(' + config.imageBaseUrl + picture.name + ')' }"/>
          <p class="imageNumber">{{ index + 1 }}</p>
        </div>
        <div class="lsc-clear"></div>
      </div>
      <div v-if="images.length === 1" class="lsc-clear"></div>
    </div>
    <!-- VISITS  -->

    <h2 class="headline">{{ x('visits') }}:</h2>
    <progressive-list-with-columns :items="visits" v-if="!isLoading" :containerId="containerId"
                                   :columnWidth="120" class="displaymode_smallcards"
                                   no-more-elements-text="noVisitsForCustomer">
      <template v-slot="itemData">
        <feed-item :model="itemData.item"/>
      </template>
    </progressive-list-with-columns>
    <p v-if="!isLoading">
      <delete-button v-if="user.userRole === 'internal'"
                     delete-label="deleteCustomer" class="fullWidth"
                     @click="deleteCustomer"
                     :disabled="deleteDisabled"/>
    </p>
  </div>
</template>

<script>
  import axios from 'axios';
  import { mapState } from 'vuex';
  import { cloneDeep } from 'lodash';
  import { httpDelete, httpGet, httpPut } from '@/classes/httpHelper';
  import DeleteButton from '@/components/Survey/InputFields/DeleteButton';
  import ImageSwipe from '@/classes/imageSwipe';
  import ComponentEventNames from '@/enums/component-event-names';
  import { randomStringGenerator } from '@/classes/random-string-generator';
  import translate from '../Mixins/Translate';
  import MapComponent from '../MapComponent';
  import FeedItem from '../Pages/FeedItem';
  import ProgressiveListWithColumns from '../ProgressiveListWithColumns';

  export default {
    name: 'customer-page',
    mixins: [translate],
    components: {
      DeleteButton,
      MapComponent,
      FeedItem,
      ProgressiveListWithColumns,
    },
    emits: [ComponentEventNames.didLoad],
    props: ['customer', 'title'],
    data() {
      return {
        containerId: `customer-page-${randomStringGenerator(8)}`,
        visits: [],
        showSmall: true,
        isLoading: true,
        deleteWasPerformed: false,
        isEditingAddress: false,
        isUploading: false,
        isLocationDirty: false,
        // Prop cloned in order to be able to update it after editing.
        loadedCustomer: cloneDeep(this.customer),
        editedData: {
          name: this.customer.name,
          email: this.customer.email,
          address: this.customer.address,
          postalCode: this.customer.postalCode,
          city: this.customer.city,
          latitude: this.customer.latitude,
          longitude: this.customer.longitude,
        },
        images: [],
      };
    },
    computed: {
      position() {
        if (this.isEditingAddress) {
          return [{
            latitude: this.editedData.latitude,
            longitude: this.editedData.longitude,
          }];
        }
        if (this.loadedCustomer.latitude && this.loadedCustomer.longitude) {
          return [{
            latitude: this.loadedCustomer.latitude,
            longitude: this.loadedCustomer.longitude,
          }];
        }

        return null;
      },
      deleteDisabled() {
        return this.visits.length > 0 || this.deleteWasPerformed;
      },
      ...mapState(['config', 'user']),
    },
    async mounted() {
      this.$emit(ComponentEventNames.didLoad);
      await this.loadVisits();
      await this.loadCustomerImages();
      window.itemSwipe = new ImageSwipe(`#${this.containerId} .swipe_container`);
    },
    methods: {
      async loadCustomerImages() {
        console.log('LoadCustomerImages');
        this.images = await httpGet(`customers/${this.customer.id}/pictures`);
      },
      zoomImage(imageIndex) {
        const imageUrl = this.config.imageBaseUrl + this.images[imageIndex].name;
        this.$store.commit('setZoomImage', imageUrl);
      },
      swipeToImage(imageIndex) {
        console.log(`Swipe to image ${imageIndex}`);
        window.itemSwipe.goToImage(imageIndex + 1);
      },
      async loadVisits() {
        this.visits = await httpGet(`customers/${this.loadedCustomer.id}/visits`);
        this.isLoading = false;
      },
      async deleteCustomer() {
        if (this.deleteDisabled) return;

        try {
          await httpDelete(`customers/${this.loadedCustomer.id}/ifempty`);
          console.log('Customer deleted.');
        } catch (e) {
          console.error(e.error.message, e);
        }

        this.deleteWasPerformed = true;
      },
      openMap() {
        // eslint-disable-next-line max-len
        window.open(`https://www.google.com/maps/search/?api=1&query=${this.loadedCustomer.latitude},${this.loadedCustomer.longitude}`);
      },
      editAddress() {
        this.isEditingAddress = true;
      },
      async lookupAddress() {
        if (!this.isLocationDirty) {
          console.log('Location has not changed. No lookup performed.');
          return;
        }
        const address = `${this.editedData.address}, ${this.editedData.postalCode} ${this.editedData.city}`;
        console.log(`Looking up address: ${address}`);
        const apiKey = this.$store.state.config.obj_texts.MapApiKey;
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${
          address}&key=${apiKey}`);
        if (response.data.status === 'OK') {
          const result = response.data.results[0];
          const location = result.geometry.location;
          this.editedData.latitude = location.lat;
          this.editedData.longitude = location.lng;
          this.isLocationDirty = false;
        } else {
          console.warn('Address lookup failed.');
        }
      },
      async saveCustomer() {
        this.isUploading = true;
        await this.$nextTick();
        if (this.isLocationDirty) {
          await this.lookupAddress();
        }
        try {
          await httpPut(`customers/${this.loadedCustomer.id}`, this.editedData);
          this.loadedCustomer = { ...this.loadedCustomer, ...this.editedData };
          for (const visit of this.visits) {
            // TODO: TEST VUE3
            // this.$set(visit, 'customer', [this.loadedCustomer]);
            visit.customer = [this.loadedCustomer];
          }
        } catch (e) {
          console.error('Error while uploading updated customer information to the server.');
          console.error(e);
        }
        this.isEditingAddress = false;
        this.isUploading = false;
      },
      cancel() {
        this.isEditingAddress = false;
      },
    },
  };
</script>

<style lang="scss" src="./CustomerPage.scss" />
